body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.noLinkDecoration {
    text-decoration: none;
}

@media print {
    @page {
        margin: 1cm;
    }

    .MuiCollapse-root {
        height: auto !important;
    }

    .no-print {
        display: none !important;
    }
}


/* Recap / News CSS */
.newsContent img, .newsContent video, .newsContent iframe {
    /*min-width: 100%; */
    max-width: 100%;
}

.newsContent img {
    height: auto;
}

.newsContent iframe {
    max-width: 100%;
    max-height: calc((100vw - 40px) / (16/9))
}

.newsContentContainer {
    max-width: 100%; /* Ensure the content does not exceed the width of its container */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

.cospar-logo-reduced {
    max-width: 344px;
    max-height: 372px;
}
